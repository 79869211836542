export const MainConfig = {
    AppDetails: {
      app_name: "GogaCab",
      app_description: "GogaCab Taxi Booking App",
      app_identifier: "com.gogacab",
      ios_app_version: "1.1",
      android_app_version: 4
    },
    FirebaseConfig: {
      apiKey: "AIzaSyCWekMaby0kz8572dFlqsCFCkPN45neOS4",
      authDomain: "taxi-service-12f2e.firebaseapp.com",
      databaseURL: "https://taxi-service-12f2e.firebaseio.com",
      projectId: "taxi-service-12f2e",
      storageBucket: "taxi-service-12f2e.appspot.com",
      messagingSenderId: "533721749315",
      appId: "1:533721749315:web:e5c617686ce0cc755d01f6",
      measurementId: "G-TR530RZWHP"
    },
    Google_Map_Key: "AIzaSyAPxU3DSyvlSel6WVcWgYbzgqfdi8TsWvI",
    facebookAppId: "1335129543515107",
    PurchaseDetails: {
              CodeCanyon_Purchase_Code: "86a122f2-c81c-4391-9a14-52012b86c542",
              Buyer_Email_Address: "info@trentiums.com"
          }
  }