export const features =  {
    AllowCriticalEditsAdmin:true,
    AllowCountrySelection:true,
    WebsitePagesEnabled:true,
    MobileLoginEnabled:true,
    FacebookLoginEnabled:true,
    AppleLoginEnabled:true,
    CompanyName: 'Trentium Solution',
    CompanyWebsite:"http://www.trentiums.com/",
    CompanyTerms: "http://www.trentiums.com/",
    TwitterHandle: "https://twitter.com/trentiums",
    FacebookHandle: "https://facebook.com/trentiums",
    AppleStoreLink: "https://apps.apple.com/app/id1501332146#?platform=iphone",
    PlayStoreLink: "https://play.google.com/store/apps/details?id=com.exicube.grabcab"
};